// 3rd party
import { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import useIsInViewport from '@/hooks/useIntersection'
// Components
// import DefaultSlider from '@/components/defaultSlider/defaultSlider'
const DefaultSlider = dynamic(() => import('@/components/defaultSlider/defaultSlider'), {
  loading: () => null,
  ssr: true,
})
// import SliderCard from '@/components/SliderCard/SliderCard'
const SliderCard = dynamic(() => import('@/components/SliderCard/SliderCard'), {
  loading: () => null,
  ssr: true,
})
// import CategoryCard from '@/components/CategoryCard/CategoryCard'
const CategoryCard = dynamic(() => import('@/components/CategoryCard/CategoryCard'), {
  loading: () => null,
  ssr: true,
})
// import ProductCard from '@/components/ProductCard/ProductCard'
const ProductCard = dynamic(() => import('@/components/ProductCard/ProductCard'), {
  loading: () => null,
  ssr: true,
})
// import SubCategorySlider from '@/components/SubCategorySlider/SubCategorySlider'
const SubCategorySlider = dynamic(
  () => import('@/components/SubCategorySlider/SubCategorySlider'),
  {
    loading: () => null,
    ssr: true,
  }
)
// import CTA from '@/components/CTA'
const CTA = dynamic(() => import('@/components/CTA'), {
  loading: () => null,
  ssr: true,
})

// Helpers
import { formatUrlSlug, getContentModel } from '@/lib/helper'

// Contentful
import { enableSlidersPreview } from '@/lib/contentful/module'
import dynamic from 'next/dynamic'
import { viewContentPromotionEvent } from '@/lib/ga-events'
import { default as pagePaths } from '../../public/promotion-urls.json'

const ModuleSliders = (props) => {
  const { preview } = props
  const router = useRouter()
  const [item, setItem] = useState(props)
  const [success, setSuccess] = useState(false)
  const ref1 = useRef(null)
  const [promotionEventData, setPromotionEventData] = useState(null)
  let pageModel = getContentModel('page')
  let sliderModel = getContentModel('moduleSliders')
  useEffect(async () => {
    if (preview) {
      const res = await enableSlidersPreview(props, preview)
      setItem(res)
    }
  }, [])

  const inViewport = useIsInViewport(ref1)

  const paddingTop =
    item.sectionPaddingTop > 0
      ? `md:pt-[${item.sectionPaddingTop}px]`
      : item.sectionPaddingTop === 0 && 'md:pt-0'
  const sliderData =
    item.contentBlock &&
    item.contentBlock.length > 0 &&
    item.contentBlock.map((content) => {
      return {
        imgPath:
          content.fields?.coverImage?.fields.file.url ||
          content.fields?.backgroundImage?.fields.file.url ||
          (preview && content?.coverImage?.url),
        altTag: content.fields?.backgroundImage?.fields?.file
          ? content.fields?.backgroundImage?.fields?.description
          : '',
        width:
          content.fields?.coverImage?.fields.file.details.image.width ||
          content.fields?.backgroundImage?.fields.file.details.image.width ||
          (preview && content?.coverImage?.width),
        height:
          content.fields?.coverImage?.fields.file.details.image.height ||
          content.fields?.backgroundImage?.fields.file.details.image.height ||
          (preview && content?.coverImage?.height),
        firstLabl: content.fields?.eyebrow || content.eyebrow,
        title: content.fields?.title || content.title,
        entryTitle: content.fields?.entryTitle || content.entryTitle,
        time: content.fields?.timeToRead || content.timeToRead,
        excerpt: content.fields?.excerpt || content.excerpt,
        link: `/${formatUrlSlug(content.fields?.slug || content.slug)}`,
        linkhref: content.fields?.ctaUrl || `/${formatUrlSlug(content.fields?.ctaUrl)}`, // need fix
        sys: item.sys,
        preview: preview,
      }
    })

  //for productslide Ids

  const productSlideIds = []
  item.contentBlock &&
    item.contentBlock.length > 0 &&
    item.contentBlock.map((content) => {
      if (preview) {
        content?.productId && productSlideIds.push(content?.productId)
      } else {
        content?.fields?.productId && productSlideIds.push(content?.fields?.productId)
      }
    })

  //end here

  const cta = {
    id: item?.cta?.id || null,
    label: item?.cta?.label || item?.buttonTitle,
    openInNewTab: item?.cta?.openInNewTab || false,
    openVideoPlayer: item?.cta?.openVideoPlayer || false,
    url: item?.cta?.url || item?.buttonUrl,
    bgColor: item?.cta?.backgroundColor,
  }

  const subtitleCta = {
    id: item?.subtitleCta?.id || null,
    label: item?.subtitleCta?.label || item?.subtitle,
    openInNewTab: item?.subtitleCta?.openInNewTab || false,
    openVideoPlayer: item?.subtitleCta?.openVideoPlayer || false,
    url: item?.subtitleCta?.url || item?.subtitleUrl,
    bgColor: item?.subtitleCta?.backgroundColor,
  }

  const backgroundColor = item.background || 'White'
  const progressBar = item.showProgressBar
  const useSearchSpring = item.useSearchSpring
  const searchSpringTag = item.searchSpringProfileTag
  const searchspringCategories = item?.searchspringCategories
  const searchspringNoOfProducts = item?.searchspringNoOfProducts
  const searchspringPdpProductId = item?.searchspringPdpProductId
  let finalSku = null
  let showSlider = true

  if (searchSpringTag == 'previously-viewed') {
    const cookieSkus = Cookies.get('previouslyViewed')
    if (cookieSkus && cookieSkus.includes(',')) {
      let skusList = cookieSkus.split(',')
      let indexOfSku = cookieSkus.indexOf(props?.product?.sku)
      indexOfSku != -1 && delete skusList[indexOfSku]
      finalSku = skusList.filter((n) => n)
    } else {
      showSlider = false
    }
  }

  useEffect(() => {
    if (inViewport) {
      if (success) return

      const sliderPromotion = () => {
        let currentPath = router.asPath
        let promotionId =
          (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL) + currentPath
        promotionId = promotionId.startsWith('http') ? promotionId : 'https://' + promotionId
        let promotionName
        let creativeName = item.entryTitle
        let locationId = item.index + 1
        let creativeSlot = `Modules::${locationId}`
        if (currentPath == '/') {
          promotionName = `${pageModel.name}::${sliderModel.name}`
        } else {
          let pageType = pagePaths.find((page) => page.path == currentPath)
          promotionName = pageType?.type
            ? getContentModel(pageType?.type)?.name + `::${sliderModel.name}`
            : `${pageModel.name}::${sliderModel.name}`
        }

        let eventData = [
          {
            promotion_id: promotionId,
            promotion_name: promotionName,
            creative_name: creativeName,
            creative_slot: creativeSlot,
            location_id: locationId,
          },
        ]

        setPromotionEventData(eventData)
        let executed = viewContentPromotionEvent(eventData)
        if (executed) {
          setSuccess(true)
        }
      }

      const successFunction = async () => {
        try {
          sliderPromotion()
        } catch (error) {
          console.error('Function execution failed:', error)
        }
      }

      const intervalId = setInterval(async () => {
        if (!success) {
          await successFunction()
        } else {
          clearInterval(intervalId)
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [inViewport, success, router.asPath])

  return (
    <div
      ref={ref1}
      className={`
      content-up ${showSlider && 'py-[60px] md:py-[100px]'} ${paddingTop}
      ${backgroundColor == 'White' ? ' bg-white' : ' bg-sliderGray'}
      ${router.pathname == '/grant-program' && 'py-0 md:pb-0'}
      ${router.pathname == '/conservation-partners' && 'pb-[20px] md:pb-[20px] !pt-[30px]'}
    `}
    >
      {showSlider && (
        <>
          <div
            className={'container text-black w-full flex justify-between items-center mb-[26px]'}
          >
            <div className="inline-block">
              {item.eyebrow && (
                <div
                  className={`title text-sm ${
                    router.pathname === '/sitka-depot' ? 'text-[12px]' : ''
                  } font-bold uppercase text-secondarySandyGray tracking-widest`}
                >
                  {item.eyebrow}
                </div>
              )}

              {item.title && (
                <>
                  <style jsx>
                    {`
                      .title_italic {
                        font-family: FreightDisp Pro;
                        font-style: italic !important;
                        font-weight: 400 !important;
                        letter-spacing: 0.02em !important;
                      }
                    `}
                  </style>
                  <div
                    className={`text-[20px] md:text-[28px] leading-[28px] md:leading-[36px] font-bold tracking-[0.08em] ${
                      item.titleItalicEnable ? 'title_italic' : 'title uppercase'
                    } `}
                  >
                    {item.title}
                  </div>
                </>
              )}
            </div>

            {subtitleCta.url && subtitleCta.label && (
              <CTA
                className={
                  'border-b-2 border-primary font-bold uppercase text-[12px] leading-[15px] ml-5 py-1 mr-[48px] lg:mr-[160px]'
                }
                label={subtitleCta.label}
                openInNewTab={subtitleCta.openInNewTab}
                openVideoPlayer={subtitleCta.openVideoPlayer}
                url={subtitleCta.url}
                background={subtitleCta.bgColor}
                moduleProps={props}
              />
            )}
          </div>

          {sliderData && item.sliderType && item.sliderType === 'Content slider' && (
            <DefaultSlider
              children={SliderCard}
              data={sliderData && sliderData}
              id={sliderData && sliderData[0]?.sys.id}
              background={backgroundColor}
              type={item.sliderType}
              useSearchSpring={useSearchSpring}
              searchSpringTag={searchSpringTag}
              searchspringCategories={searchspringCategories}
              searchspringNoOfProducts={searchspringNoOfProducts}
              searchspringPdpProductId={searchspringPdpProductId}
              eventData={promotionEventData}
              contentBlock={item?.contentBlock || null}
              onProductLoad={props?.onProductLoad || null}
            />
          )}

          {sliderData && item.sliderType && item.sliderType === 'Category slider' && (
            <DefaultSlider
              children={CategoryCard}
              data={sliderData && sliderData}
              id={sliderData && sliderData[0]?.sys.id}
              background={backgroundColor}
              eventData={promotionEventData}
              contentBlock={item?.contentBlock || null}
              onProductLoad={props?.onProductLoad || null}
            />
          )}

          {item.sliderType && item.sliderType === 'Product slider' && (
            <DefaultSlider
              children={ProductCard}
              categoryId={item.categoryId}
              productIds={productSlideIds}
              id={`ProductCard-${item.sys.id}`}
              background={backgroundColor}
              progressBar={progressBar}
              useSearchSpring={useSearchSpring}
              searchSpringTag={searchSpringTag}              
              searchspringCategories={searchspringCategories}
              searchspringNoOfProducts={searchspringNoOfProducts}
              searchspringPdpProductId={searchspringPdpProductId}
              product={props?.product}
              finalSku={finalSku}
              eventData={promotionEventData}
              contentBlock={item?.contentBlock || null}
              sliderTitle={item?.title || ''}
              onProductLoad={props?.onProductLoad || null}
            />
          )}

          {item.sliderType && item.sliderType === 'Sub Category slider' && (
            <SubCategorySlider
              items={sliderData}
              background={backgroundColor}
              onProductLoad={props?.onProductLoad || null}
            />
          )}

          {cta.url && cta.label && (
            <div
              className={
                'slider-button container w-full flex md:hidden md:justify-end justify-center mt-[30px] conservation-button'
              }
            >
              <CTA
                className={'btn btn-primary p-[21px] min-w-full'}
                label={cta.label}
                openInNewTab={cta.openInNewTab}
                openVideoPlayer={cta.openVideoPlayer}
                url={cta.url}
                background={cta.bgColor}
                inlineStyles={`
                @media screen and (min-width: 768px) {
                  .conservation-button {
                    display: grid;
                    grid-template-columns: repeat(8, 1fr);
                  }
                  .conservation-button * {
                    min-width: 240px;
                    grid-column-start: 6;
                    grid-column-end: 8;
                  }
                }
                @media screen and (min-width: 1024px) {
                  .conservation-button {
                    grid-template-columns: repeat(12, 1fr);
                  }
                  .conservation-button * {
                    grid-column-start: 10;
                    grid-column-end: 12;
                  }
                }
                @media screen and (min-width: 1280px) {
                  .conservation-button {
                    grid-template-columns: repeat(12, 1fr);
                  }
                  .conservation-button * {
                    grid-column-start: 8;
                    grid-column-end: 10;
                  }
                }
                @media screen and (min-width: 1440px) {
                  .conservation-button {
                    grid-template-columns: repeat(20, 1fr);
                  }
                  .conservation-button * {
                    grid-column-start: 12;
                    grid-column-end: 16;
                  }
                }
              `}
                moduleProps={props}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default ModuleSliders
